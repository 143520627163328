import React from "react";

const BatchingItems = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        After we have funneled all of your orders into your account, we need to
        start organizing them for production. This is done through “batching.”
        You can find information about batching here.
      </p>

      <a
        href="https://help.riverr.app/en/understanding-the-production-workflow/items-index-page-1"
        target="_blank"
      >
        Batching Your Orders
      </a>
    </div>
  );
};

export default BatchingItems;
