import { createAction } from "redux-actions";
import {
  REQUEST_GRAPH_DATA_SUCCESS,
  REQUEST_GRAPH_DATA_FAILURE,
  REQUEST_PRINTING_STATS_SUCCESS,
  REQUEST_PRINTING_STATS_FAILURE,
  UPDATE_GETTING_STARTED_STEPS,
  UPDATE_CURRENT_ACTIVE_STEP,
  UPDATE_RETAIL_PRODUCT_STEPS,
  UPDATE_CUSTOMIZABLE_PRODUCT_STEPS,
  UPDATE_PRE_PRODUCTION_STEPS,
  UPDATE_PRODUCTION_STEPS,
  UPDATE_DASHBOARD_TASKS_SUCCESS,
  UPDATE_DASHBOARD_TASKS_FAILURE,
} from "../constants/actionTypes";
import * as Dashboard from "../api/dashboard";

const requestGraphDataSuccess = createAction(REQUEST_GRAPH_DATA_SUCCESS);
const requestGraphDataFailure = createAction(REQUEST_GRAPH_DATA_FAILURE);

export function requestGraphData(params) {
  return (dispatch) =>
    Dashboard.requestGraphData(params)
      .then(({ data }) => dispatch(requestGraphDataSuccess(data)))
      .catch((error) => dispatch(requestGraphDataFailure(error)));
}

const requestPrintingStatsSuccess = createAction(
  REQUEST_PRINTING_STATS_SUCCESS
);
const requestPrintingStatsFailure = createAction(
  REQUEST_PRINTING_STATS_FAILURE
);

export function requestPrintingStats(params) {
  return (dispatch) =>
    Dashboard.requestPrintingStats(params)
      .then(({ data }) => dispatch(requestPrintingStatsSuccess(data)))
      .catch((error) => dispatch(requestPrintingStatsFailure(error)));
}

const updateDashboardTasksSuccess = createAction(
  UPDATE_DASHBOARD_TASKS_SUCCESS
);
const updateDashboardTasksFailure = createAction(
  UPDATE_DASHBOARD_TASKS_FAILURE
);

export const updateGettingStartedStepsCompleted = createAction(
  UPDATE_GETTING_STARTED_STEPS
);

export const updateRetailProductStepsCompleted = createAction(
  UPDATE_RETAIL_PRODUCT_STEPS
);

export const updateCustomizableProductStepsCompleted = createAction(
  UPDATE_CUSTOMIZABLE_PRODUCT_STEPS
);

export const updatePreProductionStepsCompleted = createAction(
  UPDATE_PRE_PRODUCTION_STEPS
);

export const updateProductionStepsCompleted = createAction(
  UPDATE_PRODUCTION_STEPS
);

export function updateDashboardTasks(params, stepsType) {
  return (dispatch) =>
    Dashboard.updateDashboardTasks(params)
      .then(({ data }) => dispatch(updateDashboardTasksSuccess(data)))
      .catch((error) => dispatch(updateDashboardTasksFailure(error)));
}

export const updateCurrentActiveStep = createAction(UPDATE_CURRENT_ACTIVE_STEP);
