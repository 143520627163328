import React, { useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VideoPlayer from "../../../components/common/VideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboardTasks } from "../../../actions/dashboard";
import AppButton from "../../../components/common/AppButton";
import { isBackStoryPrintsDomain } from "../../../utils";

const VIDEO_OPTIONS = {
  1: isBackStoryPrintsDomain
    ? "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2FgettingStartedBackStoryPrints.mp4?alt=media&token=97ad0b76-73df-4726-aa50-019c1b7718b1"
    : "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2FGettingStarted.mp4?alt=media&token=0acd9b87-7eb9-4174-adf2-17131525b094",
};

const WatchThisFirst = () => {
  const [activePlayOption, setActivePlayOption] = useState(null);
  const dispatch = useDispatch();

  const stepsCompleted = useSelector(
    (state) => state?.dashboard?.gettingStartedStepsDone
  );

  const onPlayClick = (index) => {
    setActivePlayOption(index);

    if (!stepsCompleted.includes("7_5")) {
      const params = { tasks: { watchThisFirst: true } };
      dispatch(updateDashboardTasks(params));
    }
  };

  return (
    <div className="my-3">
      <p>
        Let’s get started with a video walkthrough showing you how to add your
        billing info and sync your orders
      </p>

      <div className="videoButtonsContainer">
        <AppButton className="m-1" onClick={() => onPlayClick(1)} size="slim">
          <PlayArrowIcon /> Watch this first!
        </AppButton>
      </div>

      {activePlayOption && (
        <VideoPlayer
          url={VIDEO_OPTIONS[activePlayOption]}
          open={activePlayOption}
          handleClose={() => setActivePlayOption(null)}
          onEnded={() => setActivePlayOption(null)}
          controls
        />
      )}
    </div>
  );
};

export default WatchThisFirst;
