import { uniqBy } from "lodash";

import {
  REQUEST_ORDERS_SUCCESS,
  REQUEST_ORDERS_FAILURE,
  REQUEST_ORDERS_COUNT_SUCCESS,
  REQUEST_ORDERS_COUNT_FAILURE,
  REQUEST_ORDERS_DETAILS_SUCCESS,
  REQUEST_ORDERS_DETAILS_FAILURE,
  REQUEST_INITIAL_DRAFT_ORDERS_SUCCESS,
  REQUEST_DRAFT_ORDERS_SUCCESS,
  REQUEST_INITIAL_DRAFT_ORDERS_FAILURE,
  REQUEST_INITIAL_UNSYNCED_ORDERS_SUCCESS,
  REQUEST_ORDER_DETAILS_SUCCESS,
  UPDATE_ORDER_SUCCESS,
  DELETE_ORDER_SUCCESS,
  UPDATE_SHIPPING_SPEED,
  UPDATE_DRAFT_ORDER_SUCCESS,
  REQUEST_ORDER_ITEM_LOCATIONS_SUCCESS,
  REQUEST_UNSYNCED_ORDERS_SUCCESS,
  SET_LAST_ORDER_ID,
  SET_LAST_DRAFT_ORDER_ID,
  REQUEST_SHARED_DRAFT_ORDERS_SUCCESS,
  REQUEST_SHARED_UNSYNCED_ORDERS_SUCCESS,
  REQUEST_ADMIN_DRAFT_ORDERS_SUCCESS,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  orders: [],
  draftOrders: [],
  unSyncedOrders: [],
  orderDetails: {},
  lastOrderId: null,
  lastDraftOrderId: null,
  adminDraftOrders: [],
  previousStatus: null,
};

export default function orders(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_ORDERS_SUCCESS:
      const payload = action?.payload?.data?.data?.orders || [];
      const currentStatus = action?.payload?.params?.status;
      const resetOrders = action?.payload?.options?.resetOrders;

      if (currentStatus !== state.previousStatus) {
        return {
          ...state,
          orders: payload,
          previousStatus: currentStatus,
        };
      }
      let newOrders = uniqBy([...state.orders, ...payload], (x) => x?.id);

      if (resetOrders) {
        newOrders = uniqBy(payload, (x) => x?.id);
      }

      // if (action?.payload?.params?.status === "shipped") {
      //   newOrders = newOrders.filter((f) => f.shipped);
      //   // } else if (action?.payload?.params?.status === "draft") {
      //   //   newOrders = newOrders.filter((f) => f.draft);
      //   // } else if (action?.payload?.params?.status === "cancelled") {
      //   //   newOrders = newOrders.filter((f) => f.cancelled);
      //   // } else if (action?.payload?.params?.status === "unsynced") {
      //   //   newOrders = newOrders.filter((f) => f.mapped === "unMapped");
      //   // } else if (action?.payload?.params?.status === "Synced") {
      //   //   newOrders = newOrders.filter((f) => f.mapped === "mapped");
      // } else if (action?.payload?.params?.status === "unshipped") {
      //   newOrders = newOrders.filter((f) => !f.shipped);
      // } else if (action?.payload?.params?.draft) {
      //   newOrders = newOrders.filter((f) => f.draft);
      // }

      // if (action?.payload?.params?.orderBy === "desc") {
      //   newOrders.sort(
      //     (a, b) => b?.createdAt?._seconds - a?.createdAt?._seconds
      //   );
      // } else {
      //   newOrders.sort(
      //     (a, b) => a?.createdAt?._seconds - b?.createdAt?._seconds
      //   );
      // }

      return {
        ...state,
        orders: newOrders,
      };

    case REQUEST_ORDERS_DETAILS_SUCCESS:
      let { order = {}, orderItems = [] } = action?.payload?.data || {};

      order = {
        ...order,
        orderItems,
      };

      return {
        ...state,
        order: uniqBy([...state.orders, order], (x) => x?.id),
      };

    case REQUEST_ORDERS_FAILURE:
    case REQUEST_ORDERS_DETAILS_FAILURE:
      return { ...state, error: "Record not found" };

    case REQUEST_INITIAL_DRAFT_ORDERS_SUCCESS:
      return {
        ...state,
        draftOrders: uniqBy(action?.payload?.orders || [], (x) => x.id),
        error: null,
      };

    case REQUEST_DRAFT_ORDERS_SUCCESS:
      const ndo = [...state.draftOrders, ...(action?.payload?.orders || [])];
      return {
        ...state,
        draftOrders: uniqBy(ndo, (x) => x.id),
        error: null,
      };

    case REQUEST_ORDERS_COUNT_SUCCESS:
      let counts = action.payload?.data?.counts || {};
      return {
        ...state,
        counts,
      };

    case REQUEST_ORDERS_COUNT_FAILURE:
      return { ...state, error: "Record not found" };

    case REQUEST_ADMIN_DRAFT_ORDERS_SUCCESS:
      const nado = [
        ...state.adminDraftOrders,
        ...(action?.payload?.orders || []),
      ];
      return {
        ...state,
        adminDraftOrders: uniqBy(nado, (x) => x.id),
        error: null,
      };

    case REQUEST_SHARED_DRAFT_ORDERS_SUCCESS:
      const nsdo = [...(action?.payload?.orders || [])];
      return {
        ...state,
        draftOrders: uniqBy(nsdo, (x) => x.id),
        error: null,
      };

    case REQUEST_SHARED_UNSYNCED_ORDERS_SUCCESS:
      const nsuo = [...(action?.payload?.orders || [])];
      return {
        ...state,
        unSyncedOrders: uniqBy(nsuo, (x) => x.id),
        error: null,
      };

    case REQUEST_INITIAL_DRAFT_ORDERS_FAILURE:
      return { ...state, error: "Failure." };

    case REQUEST_INITIAL_UNSYNCED_ORDERS_SUCCESS:
      return {
        ...state,
        unSyncedOrders: uniqBy(action?.payload?.orders || [], (x) => x.id),
        error: null,
      };

    case REQUEST_UNSYNCED_ORDERS_SUCCESS:
      const usOrds = [
        ...state.unSyncedOrders,
        ...(action?.payload?.orders || []),
      ];
      return {
        ...state,
        unSyncedOrders: uniqBy(usOrds, (x) => x.id),
        error: null,
      };

    case REQUEST_ORDER_DETAILS_SUCCESS:
      const orderId = action?.payload?.id;
      let responsePayload = action?.payload?.data;
      // console.log(action?.payload);
      // const ordItms = (responsePayload?.orderItems || []).map((oi) => {
      //   return {
      //     ...oi,
      //     purchaseOrderData: {
      //       ...oi?.purchaseOrderData,
      //       placements: ["1", "2"],
      //       images: (oi?.purchaseOrderData?.placements || ["1", "2"]).map(
      //         (p) => ({ placement: p })
      //       ),
      //     },
      //   };
      // });
      // responsePayload = {
      //   ...responsePayload,
      //   orderItems: ordItms,
      // };
      // console.log(responsePayload);

      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          [orderId]: responsePayload,
        },
      };

    case REQUEST_ORDER_ITEM_LOCATIONS_SUCCESS:
      const { orderItemLocations } = action?.payload?.data;

      return {
        ...state,
        orderItemLocations: {
          ...state.orderItemLocations,
          [action?.payload?.id]: orderItemLocations,
        },
      };

    case UPDATE_ORDER_SUCCESS:
      const updatedOrder = action?.payload?.data?.order;
      const id = action?.payload?.orderId;

      const updatedOrders = state.orders.map((ord) => {
        if (ord.id === id) {
          return {
            ...ord,
            ...updatedOrder,
          };
        }

        return ord;
      });

      return {
        ...state,
        orderDetails: {
          ...state?.orderDetails,
          [id]: {
            ...state?.orderDetails?.[id],
            order: updatedOrder,
          },
        },
        orders: updatedOrders,
      };

    case UPDATE_SHIPPING_SPEED:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          [action.payload?.orderId]: {
            ...state.orderDetails?.[action.payload?.orderId],
            shippingSpeedId: action.payload?.data?.order?.shippingSpeedId,
          },
        },
      };

    case DELETE_ORDER_SUCCESS:
      const deletableId = action?.payload?.orderId;
      let temp = { ...state.orderDetails };
      delete temp[deletableId];

      return {
        ...state,
        orderDetails: temp,
      };

    case UPDATE_DRAFT_ORDER_SUCCESS:
      console.log(action?.payload);

      return {
        ...state,
      };

    case SET_LAST_ORDER_ID:
      return {
        ...state,
        lastOrderId: action.payload,
      };

    case SET_LAST_DRAFT_ORDER_ID:
      return {
        ...state,
        lastDraftOrderId: action.payload,
      };

    default:
      return state;
  }
}
