import { keyBy, keys } from "lodash";

import {
  REQUEST_SUPPLIERS_SUCCESS,
  REQUEST_SUPPLIERS_FAILURE,
  REQUEST_SUPPLIERS_LOCATIONS_SUCCESS,
  UPDATE_SUPPLIER_LOCATION_SUCCESS,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  suppliers: [],
  locations: {},
};

export default function suppliers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_SUPPLIERS_SUCCESS:
      return {
        suppliers: action?.payload?.data?.suppliers || [],
      };

    case REQUEST_SUPPLIERS_LOCATIONS_SUCCESS:
      const locations = action?.payload?.data || {};
      let temp = {};

      keys(locations).forEach((loc) => {
        temp = {
          ...temp,
          [loc]: {
            ...locations[loc],
            thislocations: keyBy(locations[loc].thislocations, "id"),
          },
        };
      });

      return {
        ...state,
        locations: temp,
      };

    case UPDATE_SUPPLIER_LOCATION_SUCCESS:
      const newData = action?.payload?.data || {};
      const newLocations = {
        ...state.locations,
        [newData?.id]: {
          ...state?.locations?.[newData?.id],
          locationId: newData?.locationId,
        },
      };

      return {
        ...state,
        locations: newLocations,
      };

    case REQUEST_SUPPLIERS_FAILURE:
      return { ...state, error: "Record not found" };

    default:
      return state;
  }
}
