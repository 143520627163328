import { find, isEmpty } from "lodash";

import COUNTRIES from "../constants/countries";

export const downloadImage = (e, image) => {
  e.preventDefault();

  const { name: imageName, url } = image;

  fetch(url, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${imageName}.png`);
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const generateKey = (pre = "") => {
  return `${pre}_${new Date().getTime()}`;
};

export const generateID = () => {
  let array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = "";
  for (let i = 0; i < array.length; i++) {
    str += (i < 2 || i > 5 ? "" : "-") + array[i].toString(16).slice(-4);
  }
  return str;
};

export const getCountryFromId = (id) => {
  return find(COUNTRIES, (c) => c.country_id == id);
};

export const testDomains = [
  "localhost",
  "riverr-enterprise-user.web.app",
  "riverr-enterprise-test.web.app",
  "spenterprise1.myriverr.com",
  "riverr-enterprise-user--new-ui-payments-qwd80gch.web.app",
];

export const isTestEnv = testDomains.includes(window.location.hostname);

export const fileNameExtractor = (file) => (file?.name || "").split(".")?.[0];

const PIXEL_TO_INCH_CONST = 96;

export const pixelToInch = (px, maxPix, sizeIn) => (sizeIn / maxPix) * px;
export const inchToPixel1 = (inch, maxIn, sizePix) => (sizePix / maxIn) * inch;

export const inchToPixel = (inch) => inch * PIXEL_TO_INCH_CONST;

export const pxToInch = (px) => px / PIXEL_TO_INCH_CONST;

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export function invertColor(hex = "#ffffff") {
  if (!hex) {
    return "";
  }
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    return "";
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function diffHours(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
}

export function downloadXmlFile(xmlString, fileName) {
  const blob = new Blob([xmlString], { type: "text/xml" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export const getFirstIncompleteStep = (stepsCompleted, steps) => {
  const stepsKeys = steps.map((f) => f.key);

  if (isEmpty(stepsCompleted)) {
    return stepsKeys[0];
  }

  const result = find(
    stepsKeys,
    (element) => !stepsCompleted.includes(element)
  );

  return result;
};
