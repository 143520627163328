export const STATIC_PUBLIC_FRONT =
  "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2Ffront.png?alt=media&token=9c1ab03c-17a1-4dc6-814c-6fd9952395d3";

export const STATIC_PUBLIC_BACK =
  "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2Fback.png?alt=media&token=d436334b-1728-4722-ba93-ab2b0d86b859";

export const STATIC_PUBLIC_SLEEVE =
  "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2Fsleeve.png?alt=media&token=e4ebee7f-cd78-49f3-b8ad-eb9c7908b117";

export const STATIC_PUBLIC_NECK_LABEL =
  "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2Fneck%20label.png?alt=media&token=a659b817-6f18-4229-82f2-0e84b945a0eb";

export const STATIC_PUBLIC_TRANSPARENT_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2Ftransparent_image.png?alt=media&token=57cb1b57-a835-453d-ba5c-b8461592aa3e";

export const CONSUMER_KEY = "Kykpyje2bhg3265E3KDB8ttSt4rZC5IT";
