import React from "react";

const AddIntegration = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        Before going any further, you will need to add any necessary
        integrations that you will be using to fulfill your orders.
      </p>
      <p>
        This includes your shipping aggregator and your blank product suppliers.
      </p>
      <p>
        This is also where our in-house applications live for connecting with
        your apparel printers and shipping label printers. More on that later!
      </p>
    </div>
  );
};

export default AddIntegration;
