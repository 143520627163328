import { createAction } from "redux-actions";
import {
  GET_PAYTMENT_METHODS_SUCCESS,
  GET_PAYTMENT_METHODS_FAILURE,
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
  SET_DEFAULT_PAYMENT_METHOD_FAILURE,
  GET_PAYTMENT_METHODS_LOADING,
} from "../constants/actionTypes";
import * as Credentials from "../api/credentials";

const getPaymetMethodsSuccess = createAction(GET_PAYTMENT_METHODS_SUCCESS);
const getPaymetMethodsFailure = createAction(GET_PAYTMENT_METHODS_FAILURE);
const setPaymentsLoading = createAction(GET_PAYTMENT_METHODS_LOADING);

export function getPaymetMethods(params) {
  return async (dispatch) => {
    dispatch(setPaymentsLoading(true));

    try {
      try {
        const { data } = await Credentials.getPaymetMethods(params);
        return dispatch(getPaymetMethodsSuccess(data));
      } catch (error) {
        return dispatch(getPaymetMethodsFailure(error));
      }
    } finally {
      dispatch(setPaymentsLoading(false));
    }
  };
}

const setDefaultPaymentMethodSuccess = createAction(
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS
);
const setDefaultPaymentMethodFailure = createAction(
  SET_DEFAULT_PAYMENT_METHOD_FAILURE
);

export function setDefaultPaymentMethod(params) {
  return (dispatch) =>
    Credentials.setDefaultPaymentMethod(params)
      .then(({ data }) => dispatch(setDefaultPaymentMethodSuccess(data)))
      .catch((error) => dispatch(setDefaultPaymentMethodFailure(error)));
}
