import { uniqBy, keyBy, omit } from "lodash";

import {
  REQUEST_AND_RESET_PRODUCTS_SUCCESS,
  REQUEST_PRODUCTS_SUCCESS,
  REQUEST_PRODUCTS_FAILURE,
  // REQUEST_PRODUCTS_DETAILS_SUCCESS,
  REQUEST_PRODUCTS_DETAILS_FAILURE,
  REQUEST_MANUAL_PRODUCT_SUCCESS,
  REQUEST_BLANK_PRODUCT_COLORS_SUCCESS,
  REQUEST_PRODUCTS_COUNT_SUCCESS,
  REQUEST_PRODUCTS_COUNT_FAILURE,
  SAVE_LISTING_TEMPLATE_SUCCESS,
  GET_LISTING_TEMPLATE_SUCCESS,
  DELETE_LISTING_TEMPLATE_SUCCESS,
  SAVE_PRODUCT_MAPPING_SUCCESS,
  DELETE_PRODUCTS_SUCCESS,
  DELETE_PRODUCTS_FAILURE,
  UPDATE_PREVIEW_IMAGES,
  UPDATE_CANVAS_IMAGES,
  SET_SELECTED_FILTER_FOR_LISTING,
  SET_LISTING_LAST_ID,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  products: [],
  manualProducts: [],
  colors: {},
  counts: {},
  templates: {},
  previewImages: {},
  selectedFilter: "",
  lastId: "",
};

export default function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_PRODUCTS_SUCCESS:
      const payload = action?.payload?.data?.products || [];

      return {
        ...state,
        products: uniqBy(
          [...state.products, ...payload],
          (x) => x?.platformProductId
        ),
      };

    case DELETE_PRODUCTS_SUCCESS:
      const afterDelete = state?.manualProducts.filter(
        (product) => product?.id !== action?.payload?.id
      );

      return {
        ...state,
        manualProducts: afterDelete,
      };

    case DELETE_PRODUCTS_FAILURE:
      return { ...state, error: "Record not found" };

    case REQUEST_AND_RESET_PRODUCTS_SUCCESS:
      const resetPayload = action?.payload?.data?.products || [];

      return {
        ...state,
        products: resetPayload,
      };

    // case REQUEST_PRODUCTS_DETAILS_SUCCESS:
    //   let { product = {}, variants } = action?.payload?.data || {};
    //   const variantProperties = (variants?.[0]?.properties || []).map(
    //     (f) => f.name
    //   );

    //   product = {
    //     ...product,
    //     variantProperties,
    //     variants,
    //   };

    //   return {
    //     ...state,
    //     products: uniqBy(
    //       [product, ...state.products],
    //       (x) => x?.platformProductId
    //     ),
    //   };

    case REQUEST_PRODUCTS_FAILURE:
    case REQUEST_PRODUCTS_DETAILS_FAILURE:
      return { ...state, error: "Record not found" };

    case REQUEST_MANUAL_PRODUCT_SUCCESS:
      let newManualProducts = action?.payload?.data?.manualProducts || [];
      newManualProducts = [...state.manualProducts, ...newManualProducts];
      newManualProducts = uniqBy(newManualProducts, (x) => x?.id);

      return {
        ...state,
        manualProducts: newManualProducts,
      };

    case REQUEST_BLANK_PRODUCT_COLORS_SUCCESS:
      let newColors = {};
      (action.payload?.colors || []).forEach((color, index) => {
        newColors = {
          ...newColors,
          [color]: index,
        };
      });
      return {
        ...state,
        colors: newColors,
      };

    case REQUEST_PRODUCTS_COUNT_SUCCESS:
      let counts = action.payload?.data?.counts || {};
      return {
        ...state,
        counts,
      };

    case REQUEST_PRODUCTS_COUNT_FAILURE:
      return { ...state, error: "Record not found" };

    case SAVE_LISTING_TEMPLATE_SUCCESS:
      const newTemplates = {
        ...state?.templates,
        [action?.payload?.data?.id]: {
          ...(action?.payload?.params?.template || {}),
          id: action?.payload?.data?.id,
        },
      };
      return { ...state, templates: newTemplates };

    case GET_LISTING_TEMPLATE_SUCCESS:
      const templates = keyBy(action?.payload?.templates || [], "id");
      return { ...state, templates };

    case DELETE_LISTING_TEMPLATE_SUCCESS:
      const id = action?.payload?.id;
      const updatedTemplates = omit(state?.templates || {}, [id]);
      return { ...state, templates: updatedTemplates };

    case SAVE_PRODUCT_MAPPING_SUCCESS:
      const updatedProduct = action?.payload?.data;
      const updatedProductList = state?.products.map((p) => {
        if (p?.id === updatedProduct?.id) {
          return updatedProduct;
        }

        return p;
      });

      return { ...state, products: updatedProductList };

    case UPDATE_PREVIEW_IMAGES:
      return { ...state, previewImages: action?.payload };

    case UPDATE_CANVAS_IMAGES:
      return { ...state, canvasImages: action?.payload };

    case SET_SELECTED_FILTER_FOR_LISTING:
      return { ...state, selectedFilter: action.payload };

    case SET_LISTING_LAST_ID:
      return { ...state, lastId: action.payload };

    default:
      return state;
  }
}
