import React from "react";

const DirectPrintingApp = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        Our <b>Direct-Print</b> Application allows us to bypass print previews
        to send your shipping labels and packing slips directly to the printer
        on command. This will save your packaging and shipping time
        significantly.
      </p>

      <a
        href="https://help.riverr.app/en/understanding-the-production-workflow/direct-print-application"
        target="_blank"
      >
        More Info
      </a>
    </div>
  );
};

export default DirectPrintingApp;
