import React from "react";

const EditYourBlankProduct = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        In order to add a customizable product to your Shopify channel, follow
        these steps:
      </p>

      <ol>
        <li>Click on the edit button of one of your current blank products</li>
        <li>Ensure that a price table is activated for the blank product</li>
        <li>
          Fill the checkbox at the bottom of the page that says “Add
          Customizable Product to Channel.”
        </li>
        <li>Choose the options that you would like to use for that product.</li>
        <li>
          Click save! This will send the product directly to the channel for
          you.
        </li>
      </ol>
    </div>
  );
};

export default EditYourBlankProduct;
