import React from "react";

const SyncUnsyncedOrders = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        Now that you have connected your channel, we need to sync the products
        in these unsynced orders. Here is what you will do:
      </p>

      <ol>
        <li>Click on an order to go to the order details page. </li>
        <li>
          Find the product/products that need to be synced and press on “Sync
          Product.”
        </li>
        <li>
          On the product page, sync your variations. (This is only needed once.)
        </li>
        <li>
          If there are multiple unsynced products, repeat the steps above until
          every product is synced in the order. 
        </li>
        <li>
          Done! Now the order will move to “Active Orders” and the items will be
          added to the purchase order and the manufacturing area. 
        </li>
      </ol>
    </div>
  );
};

export default SyncUnsyncedOrders;
