import {
  GET_PAYTMENT_METHODS_SUCCESS,
  GET_PAYTMENT_METHODS_LOADING,
} from "../../constants/actionTypes";

const initialState = {
  paymentMethods: [],
  stripeCreds: "",
  setupIntent: {},
  loading: false,
};

const credentials = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYTMENT_METHODS_LOADING:
      state = {
        ...state,
        loading: action.payload,
      };
      break;

    case GET_PAYTMENT_METHODS_SUCCESS:
      state = {
        ...state,
        ...action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default credentials;
