// import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { requestTheme } from "../api/settings";
// import defaultLogo from "../assets/images/Logo.png";

const useTheme = () => {
  return useSelector((state) => state?.settings?.themeData || {});
};

// const useTheme = () => {
//   let domainName = window.location.hostname.split(".");
//   domainName = domainName.length > 2 ? domainName[1] : domainName[0];

//   let themeData = localStorage.getItem("riverrTheme");
//   themeData =
//     themeData && themeData !== "undefined" ? JSON.parse(themeData) : {};

//   const [theme, setTheme] = useState(themeData);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (!theme.enterprise) {
//       requestTheme({ consumer_key: "Kykpyje2bhg3265E3KDB8ttSt4rZC5IT" })
//         .then((res) => {
//           setTheme(res.data);
//           localStorage.setItem("riverrTheme", JSON.stringify(res.data));
//           setLoading(false);
//         })
//         .catch((err) => {
//           console.log(err);
//           setTheme({});
//           setLoading(false);
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     }
//     setLoading(false);
//   }, []);

//   return {
//     bgColor: {
//       background: theme.primaryGradient || theme.primaryColor,
//     },
//     color: { color: theme.tertiaryColor || theme.secondaryColor },
//     logo: theme.logo || { url: defaultLogo },
//     enterprise: theme.enterprise || {
//       name: domainName,
//     },
//     ...theme,
//     loading,
//   };
// };

export default useTheme;
