import { uniqBy, keyBy } from "lodash";
import {
  REQUEST_BATCHES_SUCCESS,
  REQUEST_BATCH_DETAILS_SUCCESS,
  REQUEST_BATCH_ITEMS_SUCCESS,
  REQUEST_BATCH_BARCODES_SUCCESS,
  REQUEST_BATCH_ITEMS_COUNT_SUCCESS,
  UNBATCH_ENTIRE_BATCH_SUCCESS,
  ARCHIVE_BATCH_SUCCESS,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  batches: [],
  error: null,
  batchDetails: {},
  batchItems: {},
  batchBarcodes: {},
  batchOrders: {},
  printingInfo: {},
  products: {},
  files: {},
};

export default function batches(state = INITIAL_STATE, action) {
  const {
    data = {},
    id: batchId,

    refreshList = false,
    batch = {},
  } = action?.payload || {};

  switch (action.type) {
    case REQUEST_BATCHES_SUCCESS:
      const { batches = [] } = data;
      let newBatches = [];

      if (refreshList) {
        newBatches = batches;
      } else {
        newBatches = [...state.batches, ...batches];
        newBatches = uniqBy(newBatches, (x) => x.id);
      }

      newBatches = newBatches.sort(
        (a, b) => b?.createdAt?._seconds - a?.createdAt?._seconds
      );

      return {
        ...state,
        batches: newBatches,
        batchDetails: {},
        error: null,
      };

    case REQUEST_BATCH_DETAILS_SUCCESS:
      const updatedBatchDetails = {
        ...state.batchDetails,
        [batch?.id]: batch,
      };

      return {
        ...state,
        batchDetails: updatedBatchDetails,
      };

    case REQUEST_BATCH_ITEMS_SUCCESS:
      const {
        items = [],
        orders = [],
        printingInfo = [],
        products = [],
        files = [],
      } = data;
      const updatedBatchItems = {
        ...state.batchItems,
        [batchId]: items,
      };

      const updatedBatchOrders = {
        ...state?.batchOrders,
        [batchId]: keyBy(orders, "id"),
      };

      const updatedBatchPrintinginfo = {
        ...state?.printingInfo,
        [batchId]: keyBy(printingInfo, "id"),
      };

      const updatedProducts = {
        ...state?.products,
        [batchId]: keyBy(products, (x) => x.product.id),
      };

      const updatedFiles = {
        ...state?.files,
        [batchId]: keyBy(files, "id"),
      };

      return {
        ...state,
        batchItems: updatedBatchItems,
        batchOrders: updatedBatchOrders,
        printingInfo: updatedBatchPrintinginfo,
        products: updatedProducts,
        files: updatedFiles,
      };

    case REQUEST_BATCH_BARCODES_SUCCESS:
      const { barcodes = [] } = data;
      const updatedBatchBarcodes = {
        ...state.batchBarcodes,
        [batchId]: barcodes,
      };

      return {
        ...state,
        batchBarcodes: updatedBatchBarcodes,
      };

    case REQUEST_BATCH_ITEMS_COUNT_SUCCESS:
      return {
        ...state,
      };

    case UNBATCH_ENTIRE_BATCH_SUCCESS:
      const newBatchList =
        state.batches.filter((batch) => batch?.id !== action?.payload) || [];
      return {
        ...state,
        batches: newBatchList,
      };

    case ARCHIVE_BATCH_SUCCESS:
      return {
        ...state,
        batches: state.batches.filter((batch) => batch.id !== action?.payload),
      };

    default:
      return state;
  }
}
