import { all } from "redux-saga/effects";

import accountSaga from "./accountSaga";
import authSaga from "./authSaga";
import layoutSaga from "./layoutSaga";
import itemsSaga from "./itemsSaga";
import forgetPasswordSaga from "./forgetPasswordSaga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    accountSaga(),
    layoutSaga(),
    itemsSaga(),
    forgetPasswordSaga(),
  ]);
}
