import ajax from "./ajax";

export function requestPricings() {
  return ajax("/pricing/plans");
}

export function upgradePlan(data) {
  return ajax("/pricing/upgrade", { method: "POST", data, server: "python" });
}

export function confirmSubscription(data) {
  return ajax("/shopify/confirm-subscription", {
    method: "POST",
    data,
    server: "python",
  });
}
