import React from "react";

const ShippingLabel = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        There are a few different options when it comes to printing your
        shipping labels and packing slips.
      </p>

      <a
        href="https://help.riverr.app/en/understanding-the-production-workflow/direct-print-application"
        target="_blank"
      >
        Shipping Label Options
      </a>
    </div>
  );
};

export default ShippingLabel;
