import React, { useEffect } from "react";

const LoadHelpCrunchChat = ({ currentUser }) => {
  // below to load help crunch chat

  const { isProUser, isEnterpriseAdmin } = currentUser;

  // useEffect(() => {
  //   if (isProUser || isEnterpriseAdmin) {
  //     window.HelpCrunch("init", "riverr", {
  //       applicationId: 1,
  //       applicationSecret:
  //         "lE7UP7lLUNWL2TIIeewg8qNXYmZWhuDCMBwW2KxKDmi0PgGH/GAmX1/QJgpD7hFM9jaJVZOLjwRTBwzY/w+dSQ==",
  //     });
  //     window.HelpCrunch("showChatWidget");
  //   }

  //   return () => window.HelpCrunch("hideChatWidget");
  // }, [isProUser, isEnterpriseAdmin]);

  // below for intercome chat

  // useEffect(() => {
  //   const { uid, email, displayName } = currentUser;

  //   window.Intercom("boot", {
  //     app_id: "d8khk5hx",
  //     name: displayName,
  //     email,
  //     user_id: uid,
  //   });

  //   return () => window.Intercom("shutdown");
  // }, []);

  return <></>;
};

export default LoadHelpCrunchChat;
