import { uniqBy } from "lodash";
import {
  REQUEST_BINS_SUCCESS,
  CREATE_BIN_SUCCESS,
  RENAME_BIN_SUCCESS,
  MAKE_BIN_AVAILABLE_SUCCESS,
  DELETE_BIN_SUCCESS,
  CLEAR_BIN_SUCCESS,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  bins: [],
  error: null,
};

export default function bins(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_BINS_SUCCESS:
      let newBins = [...state.bins, ...(action?.payload?.bins || [])];
      newBins = uniqBy(newBins, (x) => x.id);
      newBins = newBins.map((bin) => ({
        ...bin,
        orderDetails: action?.payload?.orderDetails?.[bin?.orderId],
      }));

      return {
        ...state,
        bins: newBins,
        error: null,
      };

    case CREATE_BIN_SUCCESS:
      const withAddedBins = [...state.bins, ...action.payload?.createdBins];

      return {
        ...state,
        bins: uniqBy(withAddedBins, (x) => x.id),
      };

    case RENAME_BIN_SUCCESS:
      const updatedBin2 = state.bins.map((f) => {
        if (f.id === action.payload?.binId) {
          return {
            ...f,
            name: action.payload?.params?.name,
          };
        }

        return f;
      });
      return {
        ...state,
        bins: updatedBin2,
      };

    case MAKE_BIN_AVAILABLE_SUCCESS:
      const updatedBin = state.bins.map((f) => {
        if (f.id === action.payload?.id) {
          return action.payload;
        }

        return f;
      });
      return {
        ...state,
        bins: updatedBin,
      };

    case DELETE_BIN_SUCCESS:
      const binsAfterDelete = state.bins.filter(
        (f) => f?.id !== action.payload
      );
      return {
        ...state,
        bins: binsAfterDelete,
      };
    case CLEAR_BIN_SUCCESS:
      const binsAfterClear = state.bins.map((f) => {
        if (f.id === action.payload?.id) {
          return {
            ...f,
            contents: [],
            fulfilledItems: [],
            occupied: false,
            orderId: "",
            remainingItems: [],
          };
        }

        return f;
      });
      return {
        ...state,
        bins: binsAfterClear,
      };
    default:
      return state;
  }
}
