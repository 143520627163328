import { keyBy } from "lodash";

import {
  REQUEST_USERS_TO_INVOICE_SUCCESS,
  REQUEST_INVOICES_OF_USER_SUCCESS,
  REQUEST_CURRENT_USER_INVOICES_SUCCESS,
  GENERATE_INVOICE_FOR_USER_SUCCESS,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  usersToInvoice: {},
  userInvoices: {},
};

export default function invoices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_USERS_TO_INVOICE_SUCCESS:
      const users = action?.payload?.users || {};

      return {
        ...state,
        usersToInvoice: keyBy(users, "uid"),
      };

    case REQUEST_INVOICES_OF_USER_SUCCESS:
    case REQUEST_CURRENT_USER_INVOICES_SUCCESS:
      const invoices = action?.payload?.data?.invoices || {};
      const userId = action?.payload?.userId;

      const updatedUserInvoices = {
        ...state?.userInvoices,
        [userId]: keyBy(invoices, "id"),
      };

      return {
        ...state,
        userInvoices: updatedUserInvoices,
      };

    case GENERATE_INVOICE_FOR_USER_SUCCESS:
      const userDetails = action.payload?.data?.user;
      const invoiceDetails = action.payload?.data?.invoice;

      const updatedUserInvoices2 = {
        ...state?.userInvoices,
        [userDetails?.uid]: {
          ...state?.userInvoices?.[userDetails?.uid],
          [invoiceDetails?.id]: invoiceDetails,
        },
      };

      return {
        ...state,
        userInvoices: updatedUserInvoices2,
      };

    default:
      return state;
  }
}
