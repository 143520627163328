import {
  SET_INSTRUCTION_STEPS,
  UNSET_INSTRUCTION_STEPS,
  SET_ALERT,
  SET_CIRCULAR_PROGRESS,
} from "../../constants/actionTypes";

const initialState = {
  instructionSteps: false,
  alert: {},
  circularProgress: false,
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case SET_INSTRUCTION_STEPS:
      state = {
        ...state,
        instructionSteps: true,
      };
      break;
    case UNSET_INSTRUCTION_STEPS:
      state = {
        ...state,
        instructionSteps: false,
      };
      break;
    case SET_ALERT:
      state = {
        ...state,
        alert: action.payload,
      };
      break;
    case SET_CIRCULAR_PROGRESS:
      state = {
        ...state,
        circularProgress: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default common;
