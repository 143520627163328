import {
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED,
  } from "../../constants/actionTypes";
  
  const initialState = {
    registrationError: "",
    message: null,
    loading: false,
    user: null
  };
  
  const account = (state = initialState, action) => {
    switch (action.type) {
      case REGISTER_USER:
        state = {
          ...state,
          user: null,
          loading: true,
          registrationError: null,
        };
        break;
      case REGISTER_USER_SUCCESSFUL:
        state = {
          ...state,
          loading: false,
          user: action.payload,
          registrationError: null,
        };
        break;
      case REGISTER_USER_FAILED:
        let message = action.payload;
        if (typeof message !== 'string') {
          message = 'Something went wrong';
        }
        state = {
          ...state,
          user: null,
          loading: false,
          registrationError: action.payload?.message || message,
        };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default account;
  