import { createAction } from "redux-actions";

import {
  REQUEST_PRICING_SUCCESS,
  REQUEST_PRICING_FAILURE,
  UPGRADE_PLAN_SUCCESS,
  UPGRADE_PLAN_FAILURE,
} from "../constants/actionTypes";
import * as Pricings from "../api/pricings";

const requestPricingsSuccess = createAction(REQUEST_PRICING_SUCCESS);
const requestPricingsFailure = createAction(REQUEST_PRICING_FAILURE);

export function requestPricings() {
  return (dispatch) =>
    Pricings.requestPricings()
      .then((data) => dispatch(requestPricingsSuccess(data)))
      .catch((error) => dispatch(requestPricingsFailure(error)));
}

const upgradePlanSuccess = createAction(UPGRADE_PLAN_SUCCESS);
const upgradePlanFailure = createAction(UPGRADE_PLAN_FAILURE);

export function upgradePlan(params) {
  return (dispatch) =>
    Pricings.upgradePlan(params)
      .then((data) => dispatch(upgradePlanSuccess(data)))
      .catch((error) => dispatch(upgradePlanFailure(error)));
}
