import {
  REQUEST_SHOPS_SUCCESS,
  REQUEST_SHOPS_FAILURE,
  REQUEST_PRODUCT_SHOPS_SUCCESS,
  DELETE_SHOP_SUCCESS,
  DELETE_SHOP_FAILURE,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  shops: [],
  shopsToPush: [],
};

export default function shops(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_SHOPS_SUCCESS:
      return {
        ...state,
        shops: (action?.payload?.data?.shops || []).sort(
          (a, b) => a?.createdAt?._seconds - b?.createdAt?._seconds
        ),
      };

    case REQUEST_SHOPS_FAILURE:
      return { ...state, error: "Record not found" };

    case REQUEST_PRODUCT_SHOPS_SUCCESS:
      return { ...state, shopsToPush: action?.payload?.data?.shops || [] };

    case DELETE_SHOP_SUCCESS:
      const afterDelete = state?.shops.filter(
        (shop) => shop?.id !== action?.payload?.id
      );

      return {
        ...state,
        shops: afterDelete,
      };

    case DELETE_SHOP_FAILURE:
      return { ...state, error: "Record not found" };

    default:
      return state;
  }
}
