import { useEffect } from "react";
import { useLocation } from "react-router";

import { capitalizedPathName } from "../../../helpers/string";
import useTheme from "../../../helpers/useTheme";

const NonAuthLayout = ({ children }) => {
  const { enterprise = {}, logo = {} } = useTheme();
  const location = useLocation();

  useEffect(() => {
    const title = capitalizedPathName(location.pathname);
    document.title = title + ` | ${enterprise?.name || ""}`;

    // if (logo.url) {
    //   const favicon = document.getElementById("favicon");
    //   favicon.href = logo.url;
    // }
  }, [location, enterprise, logo]);

  return <>{children}</>;
};

export default NonAuthLayout;
