import React from "react";

const CreateBlankProductStaep = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        <b>
          Creating blank products doesn’t have to be such a pain and so time
          consuming.
        </b>
      </p>

      <p>
        We’ve streamlined this process for you with ready to use mockups and
        swatches for products from major distributors.
      </p>

      <p>
        <a
          href="https://help.riverr.app/getting-started/create-distributor-products"
          target="_blank"
          style={{
            padding: 10,
            textDecoration: "underline",
            fontSize: 14,
          }}
        >
          Learn more about Distributor Blank Product Creation
        </a>
      </p>

      <p>
        Don’t use products from one of our supported distributors? That is fine!
        We’ve made it easy to create your own products as well.
      </p>

      <p>
        <a
          href="https://help.riverr.app/getting-started/create-manual-blank-products"
          target="_blank"
          style={{
            padding: 10,
            textDecoration: "underline",
            fontSize: 14,
          }}
        >
          Learn more about Manual Blank Product Creation
        </a>
      </p>
    </div>
  );
};

export default CreateBlankProductStaep;
