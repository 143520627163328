import { keyBy, omit } from "lodash";

import {
  REQUEST_BLANK_PRODUCT_SUCCESS,
  REQUEST_BLANK_PRODUCT_FAILURE,
  ADD_OR_UPDATE_BLANK_PRODUCT_SUCCESS,
  ADD_OR_UPDATE_BLANK_PRODUCT_FAILURE,
  REQUEST_BLANK_PRODUCTS_ADDED_BY_ADMIN_SUCCESS,
  REQUEST_BLANK_PRODUCTS_ADDED_BY_ADMIN_FAILURE,
  ADD_MANUAL_PRODUCT_SUCCESS,
  DELETE_BLANK_PRODUCT_SUCCESS,
  DELETE_BLANK_PRODUCT_FAILURE,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  blankProducts: {},
  addedBlankProducts: {},
};

export default function blankProducts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_BLANK_PRODUCT_SUCCESS:
      const blankProduct = action?.payload?.data?.blankProduct || {};

      return {
        ...state,
        blankProducts: {
          [blankProduct?.id]: blankProduct,
          ...state.blankProducts,
        },
      };

    case REQUEST_BLANK_PRODUCT_FAILURE:
      return { ...state, error: "Record not found" };

    case DELETE_BLANK_PRODUCT_SUCCESS:
      const afterDelete = omit(state?.addedBlankProducts, action?.payload?.id);

      return {
        ...state,
        addedBlankProducts: afterDelete,
      };

    case DELETE_BLANK_PRODUCT_FAILURE:
      return { ...state, error: "Record not found" };

    case ADD_OR_UPDATE_BLANK_PRODUCT_SUCCESS:
      const bp = action?.payload?.data || {};
      // const bpName = bp?.blankSettings?.name;
      // console.log(bp);
      // bp = omit(bp?.blankSettings, ['name']);
      // console.log(bp);

      const addedBlankProducts = {
        ...state.addedBlankProducts,
        [bp?.id]: {
          ...(state.addedBlankProducts?.[bp?.id] || {}),
          ...bp,
          blankSettings: {
            ...(bp?.blankSettings || {}),
          },
          // name: bpName,
        },
      };

      return {
        ...state,
        addedBlankProducts,
      };

    case ADD_OR_UPDATE_BLANK_PRODUCT_FAILURE:
      return { ...state, error: "Record not found" };

    case REQUEST_BLANK_PRODUCTS_ADDED_BY_ADMIN_SUCCESS:
      const blankProducts = action?.payload?.data?.blankProducts || [];

      return {
        ...state,
        addedBlankProducts: {
          ...state.addedBlankProducts,
          ...keyBy(blankProducts, "id"),
        },
      };

    case REQUEST_BLANK_PRODUCTS_ADDED_BY_ADMIN_FAILURE:
      return { ...state, error: "Record not found" };

    case ADD_MANUAL_PRODUCT_SUCCESS:
      return { ...state };

    default:
      return state;
  }
}
