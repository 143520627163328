import React from "react";
import {
  DialogContent,
  Dialog,
  DialogActions,
  Button,
} from "@material-ui/core";
import ReactPlayer from "react-player";

const VideoPlayer = ({
  url,
  open,
  handleClose,
  onEnded,
  handleNeverShowAgain,
  controls,
}) => {
  return (
    <Dialog onClose={handleClose} fullWidth maxWidth="lg" open={Boolean(open)}>
      <DialogContent className="m-auto">
        <ReactPlayer
          controls={controls}
          playing={true}
          url={url}
          onEnded={onEnded}
          width="100%"
          height="100%"
        />
      </DialogContent>

      <DialogActions>
        {handleNeverShowAgain && (
          <Button onClick={handleNeverShowAgain}>Don't Show Again</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default VideoPlayer;
