const MOCK_DATA = {
  taylor: {
    uid: "R7UPQvH1cOUPPHcgOVtcaqRycAA3",
    domain: "app.bespokethreads.com",
  },
  christina: {
    uid: "jxVdspzf5PPoZSSV7lqDNpRsHbV2",
    domain: "app.bespokethreads.com",
  },
  spEnterprise: {
    uid: "NmlegLjLlvZRzZ9PoZqQ75DsdJg1",
    domain: "spenterprise1.myriverr.com",
  },
  enterprisePro: {
    uid: "zNAlxdaZG6hKf6vexv6ljqHyP8i1",
    domain: "riverr-enterprise-user.web.app",
  },
  enterpriseUser: {
    uid: "zYSUdRYOEdeTIxnIYHS2KuoTG0l1",
    domain: "riverr-enterprise-user.web.app",
  },
  atolye: {
    uid: "qYNXwPjcY8UkXCn7yp0YbAA8Lom1",
    domain: "app.riverr.app",
  },
  haktankilic: {
    uid: "O9mj5QLhLlTr7uBtwXUZlmsmk8j2",
    domain: "app.riverr.app",
  },
  guido: {
    uid: "1nlrAGa07zbzmuOJtp3PWZM49Dr1",
    domain: "app.rakiline.com",
  },
  behighsocietee: {
    uid: "3jhK9dDPYDhWcEJRRphGCu62L3o2",
    domain: "app.rakiline.com",
  },
  dyamon: {
    uid: "ad0WwLLxhAODJzg58kmBqA9ys4j1",
    domain: "app.bespokethreads.com",
  },
  podhutUser: {
    uid: "YpgkOp5oD1bwPEg6UhCB78kwv053",
    domain: "app.podhut.com",
  },
  podHut: {
    uid: "EuTiQTUFKoOokkHcx7ocqUtYEWu2",
    domain: "app.podhut.com",
  },
  jamieUser: {
    uid: "VpD0xh54xgRGELhDAaUtnDHa2Av1",
    domain: "app.teeprintcentre.com.au",
  },
  jamieAdmin: {
    uid: "hgBF8Yhn0QYjvfSjDFWU5ze4WJR2",
    domain: "app.teeprintcentre.com.au",
  },
  vancyWear: {
    uid: "xrCzV7ZIUzUq3OYex6dWpxFApTi2",
    domain: "app.teeprintcentre.com.au",
  },
  angel: {
    uid: "RJQT1W1xUfaiYvcpKsza3rAinMi2",
    domain: "app.riverr.app",
  },
  bespokethreadsstore: {
    uid: "N6afnm2AfTMyPIgXIrZrWytsyjp2",
    domain: "app.riverr.app",
  },
  random: {
    uid: "msLBJRyUgaZ4ZfLgmkPfzUbFtUw1",
    domain: "sk7.myriverr.com",
  },
  "ibobbycore@gmail.com": {
    uid: "jyfsmSC2OKQ4VTo30TZA3Ug00Se2",
    domain: "app.podhut.com",
  },
  "gabecerebro@gmail.com": {
    uid: "jb5lvADko6b8t8T1X1r2d3ftlwZ2",
    domain: "app.jumbodtg.com",
  },
  bespoke2: {
    uid: "suHeLBblrMS5JHHsyKcNLZ4ECF53",
    domain: "app.riverr.app",
  },
  "purpleparrotms@gmail.com": {
    uid: "gW4Tyn5qZyMwrsfA3A41CPtaUDg1",
    domain: "app.riverr.app",
  },
  "shop@podhut.com": {
    uid: "AYTPiIL4cigZxMVdQ8kxMkpwCgh1",
    domain: "app.podhut.com",
  },
  amberKirkover: {
    uid: "QQE9q571klRtIYFcYEveYjq5Zw92",
    domain: "app.rakiline.com",
  },
  animextra71: {
    uid: "ByOD8U2kDFb2ATpI0FUD93jTZ5H2",
    domain: "app.bespokethreads.com",
  },
  klepperenklepper: {
    uid: "13YRZ8SxjZdDyNzd8waIzGmAWVi1",
    domain: "app.riverr.app",
  },
  riverrDemo: {
    uid: "VTHiuZSBXxREWLpzyFxKal1VdVT2",
    domain: "app.riverr.app",
  },
  forjery: {
    uid: "lELEv0AiN3YrPxoCOYjrX5cpIWo1",
    domain: "app.forjery.com",
  },
  eric: {
    uid: "O8OVDSUgMmQsg6leCvyJjS61aTh2",
    domain: "app.rakiline.com",
  },
  backStoryUser: {
    uid: "KXrJxsTpjBNdEvmosKvqkyo41Ri1",
    domain: "app.backstoryprints.com",
  },
  // dropmerch: {
  //   uid: "aQAWWRW9oKgfyUpYKfNKtQ0o6062",
  //   domain: "app.riverr.app",
  // },
  pneumalogistics: {
    uid: "WJ25OrALsiW1i1qHKj5GKotxMhG3",
    domain: "app.riverr.app",
  },
  moniqueUser: {
    uid: "KXrJxsTpjBNdEvmosKvqkyo41Ri1",
    domain: "backstoryprints.myriverr.com",
  },
  monique: {
    uid: "wvSDl9jcQRRvBAoADXD7qT2WGRp2",
    domain: "app.backstoryprints.com",
  },
  vibrasclub: {
    uid: "OCflvPR43mZlNezVLNX8fbxdjFi1",
    domain: "app.riverr.app",
  },
  backstoryprintsTestUser: {
    uid: "0nmPuMCJvkhZ40CvRKGQUIkZdBb2",
    domain: "app.backstoryprints.com",
  },
  atthemoment: {
    uid: "OqzDGClpw2PgULBakT49WwIug4G2",
    domain: "app.riverr.app",
  },
  "contact@gamechanger.la": {
    uid: "7iNwaI9hN9XmyrFFp4FqtmRSZsA3",
    domain: "app.riverr.app",
  },
  "orders@vibrasclub.com": {
    uid: "oGYxJaJswMZtuJmA9Z8l1JZ5FxZ2",
    domain: "app.riverr.app",
  },
  randomTest: {
    uid: "IH0yzaQsDgVnHA47tKsuyqiJ3Vh2",
    domain: "locationstest.myriverr.com",
  },
  printifySuperAdmin: {
    uid: "CWE1UfkYtqOUcCJtqScxQh4SyRm1",
    domain: "printify.myriverr.com",
  },
  adamPrintify: {
    uid: "Zwno5UpnoIMLRUH2ZAclsLyrL3p1",
    domain: "printify.myriverr.com",
  },
  cyrille: {
    uid: "w2SD4K2hhoV2BQB9gii1cKBy5L42",
    domain: "app.backstoryprints.com",
  },
  merchiful: {
    uid: "r2Qqv4Daf1QwQVLrpFYFOXxZ1Jx2",
    domain: "app.printondemand.com.ph",
  },
  merchifulUser: {
    uid: "mijRmLtRfjepDTQyyyJeMBogDt42",
    domain: "app.printondemand.com.ph",
  },
  apperal: {
    uid: "gG895WoY1TWSCwGgNCGupORs7Xg1",
    domain: "app.podhut.com",
  },
  printPoets: {
    uid: "DboCIpJzyRheyl0lAOGjXkVgS482",
    domain: "app.printpoets.com",
  },
  girlTribe: {
    uid: "C231P1oJ20TjvQKpCxpkDRZJYpc2",
    domain: "app.girltribeco.com",
  },
  girlTribeAdmin: {
    uid: "DboCIpJzyRheyl0lAOGjXkVgS482",
    domain: "app.girltribeco.com",
  },
  merchRobaroba: {
    uid: "uTrTZtR64LM3wtcGvDgE4ugyYA53",
    domain: "app.riverr.app",
  },
  1295: {
    uid: "HeHNgg8wOyTfKB5j1aYRkRDCpGl2",
    domain: "app.printondemand.com.ph",
  },
  infoSaintpaulbrand: {
    uid: "ukX87lql60dcjKkBSgS5SG7Nsbu2",
    domain: "larevival.myriverr.com",
  },
  benjamin: {
    uid: "6ZmCdXv7kITkVwEIcLgu9V8h3uZ2",
    domain: "app.larevivalapparel.com",
  },
  ryanThehomet: {
    uid: "MORzyKSj3bMhbYUtuRIoR88Zv4u1",
    domain: "app.rakiline.com",
  },
  ryan2Thehomet: {
    uid: "H3VPShrDPIXOqRQdep6EnBp7zXZ2",
    domain: "app.riverr.app",
  },
  maryjoycec: {
    uid: "zXCmd1bnUzV6FUIsMN21UzZuQWR2",
    domain: "app.printondemand.com.ph",
  },
  "11atGmail": {
    uid: "jfikReJHyjcfDLnv3MYbL2hfUYA3",
    domain: "app.printondemand.com.ph",
  },
  charleyd: {
    uid: "tiisNJwxkzM45qZmOFmIanEa1pR2",
    domain: "app.printondemand.com.ph",
  },
  merchmonkies: {
    uid: "dlVCdzv2cEd11PUwS1L1DdYrecE3",
    domain: "app.merchmonkies.com",
  },
  merchfillerAdmin: {
    uid: "ch05DgvKQ2Mx3Rlew2x5GWbpgjt2",
    domain: "app.merchfiller.com",
  },
  aimlessgoodsUser: {
    uid: "QFmMYOIIK6QmxGGSR3YLzqpYuWx1",
    domain: "app.aimlessgoods.com",
  },
  "support@niceshirtthanks.com": {
    uid: "MkPkZPk3QdOG3lYI0PQ1M0ckqtG3",
    domain: "app.printpoets.com",
  },
  "admin@www1.printondemand.com.ph": {
    uid: "vvd7h6upvBVDedKRv603CDCe99b2",
    domain: "app.printondemand.com.ph",
  },
  yianniartist: {
    uid: "YfgNWMogxvSveKRfNp6whYMOGlE3",
    domain: "app.teeprintcentre.com.au",
  },
  AndreaBarnes: {
    uid: "sCPqqWjEjMYDt2EQLswirFBEwa43",
    domain: "app.larevivalapparel.com",
  },
  bradAtRiverr: {
    uid: "e4hUKrwgIUWjb6dUQBzlEPYhI342",
    domain: "app.myshirtpod.com",
  },
  apprevtest0201: {
    uid: "jmkz5thJ5cSBxuJWxoxTOM6U0Gw1",
    domain: "app.myshirtpod.com",
  },
  scott: {
    uid: "dlVCdzv2cEd11PUwS1L1DdYrecE3",
    domain: "app.merchmonkies.com",
  },
  testCatalog: {
    uid: "aWotFwYovJONrrUmkiGRbri41gB2",
    domain: "app.riverr.app",
  },
  jumbodtgUser: {
    uid: "Z9b5EeEtpIWO8pyfwDGFmGAxd482",
    domain: "app.jumbodtg.com",
  },
  jumbodtgAdmin: {
    uid: "Rfv4O3keY5MSXiYyCQeB6EmXYiM2",
    domain: "app.jumbodtg.com",
  },
  dropmerchUser: {
    uid: "1asUSAsjXbbBspKLrgXa8E4OgtV2",
    domain: "app.dropmerch.co.uk",
  },
  stickygumdrop22AtgmailDotCom: {
    uid: "S6cjn7gKnDh2O6OGNaZRrZPdYlh2",
    domain: "app.podhut.com",
  },
  "info.jumbodtg@gmail.com": {
    uid: "RwEwrRPPI6boctY35yQXqJsPr4r2",
    domain: "app.jumbodtg.com",
  },
  gblhavoc: {
    uid: "vOoPE2ElE7aKqNEi0ACaBqYy99J3",
    domain: "app.jumbodtg.com",
  },
  "tinkmichelle@gmail.com": {
    uid: "dlOrPiJMCaVGjz1yFUFjzdmJOVr2",
    domain: "app.backstoryprints.com",
  },
  rendy: {
    uid: "0FekwCsc0YXSNCs2wjCwmr9DoMb2",
    domain: "app.think.pro",
  },
  dallas: {
    uid: "2xw9N2XLxmUDQvxBEqtsZOBPiLp1",
    domain: "app.dallasprinthouse.com",
  },
  jv: {
    uid: "5WgbXx4etqZAQSBvAmIuG6sD6BG3",
    domain: "app.bespokethreads.com",
  },
  PrindropSPA: {
    uid: "av2mBBtg0qhs5kgnLwyzSheKq4w2",
    domain: "app.printdrop.cl",
  },
  promiseapparealUser: {
    uid: "beSY66Q0qhPrRHVEI8voofYTVXD2",
    domain: "app.promiseappareal.com",
  },
};

export default MOCK_DATA;
