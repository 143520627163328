import { put, call, takeLatest } from "redux-saga/effects";

import { requestItems } from "../../api/items";
import {
  GET_FILTERED_ITEMS,
  REQUEST_FILTERED_ITEMS_SUCCESS,
  REQUEST_FILTERED_ITEMS_FAILURE,
  SHOW_LOADING_FALSE,
} from "../../constants/actionTypes";

function* fetchItems({ payload }) {
  try {
    const json = yield call(requestItems, payload);

    yield put({ type: REQUEST_FILTERED_ITEMS_SUCCESS, payload: json?.data || {} });
    yield put({ type: SHOW_LOADING_FALSE });
  } catch (e) {
    yield put({ type: REQUEST_FILTERED_ITEMS_FAILURE, message: e.message });    
    yield put({ type: SHOW_LOADING_FALSE });
  }
}

function* itemsSaga() {
  yield takeLatest(GET_FILTERED_ITEMS, fetchItems);
}

export default itemsSaga;
