import React from "react";

const AddPriceTableStep = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        Price tables allow you to set pricing for a variety of print methods,
        including screen print, embroidery, DTG and sublimation.
      </p>
      <a
        href="https://help.riverr.app/product-customizer/price-tables"
        target="_blank"
        style={{
          padding: 10,
          textDecoration: "underline",
          fontSize: 14,
        }}
      >
        Learn more about price tables
      </a>
    </div>
  );
};

export default AddPriceTableStep;
