import { keyBy, isEmpty } from "lodash";

import { REQUEST_PRICING_SUCCESS } from "../../constants/actionTypes";

const INITIAL_STATE = {
  pricings: [],
  activePlan: {},
  plans: [],
  planId: null,
};

export default function pricings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_PRICING_SUCCESS:
      let { plans = [], planId } = action?.payload?.data || {};
      const keyedPlans = keyBy(plans, "id");

      plans = plans.map((p) => {
        if (p.id === "WORKFLOWPRO" || p.id === "FULFILLMENTHUB") {
          p.features = keyedPlans["RIVERRPRO99"].features;
        }

        return p;
      });

      const pricings = keyBy(plans || [], "id");
      let activePlan =
        (plans || []).find((f) => f?.id === planId) ||
        pricings?.["RIVERRFREE"] ||
        {};

      if (!isEmpty(activePlan)) {
        let keyedFeatures = {};
        (activePlan?.features || []).forEach(({ id, active }) => {
          keyedFeatures = {
            ...keyedFeatures,
            [id]: active,
          };
        });
        activePlan = {
          ...activePlan,
          keyedFeatures,
        };
      }

      return {
        ...state,
        pricings,
        activePlan,
        plans,
        planId,
      };

    default:
      return state;
  }
}
