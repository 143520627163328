import { createAction } from "redux-actions";
import {
  SET_INSTRUCTION_STEPS,
  UNSET_INSTRUCTION_STEPS,
  SET_ALERT,
  SET_CIRCULAR_PROGRESS,
} from "../constants/actionTypes";

export const setInstructionsSteps = createAction(SET_INSTRUCTION_STEPS);
export const unsetInstructionsSteps = createAction(UNSET_INSTRUCTION_STEPS);
export const setGlobalAlert = createAction(SET_ALERT);
export const setCircularProgress = createAction(SET_CIRCULAR_PROGRESS);
