import React from "react";

const DesignFirstProduct = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>This is where the magic begins to happen.</p>

      <div>
        <ol>
          <li>Choose a blank product from our catalog.</li>
          <li>Add images, text, color and size variations in seconds.</li>
          <li>
            Choose the image that you would like to take center stage for every
            channel you send it to.
          </li>
          <li>Create the product!</li>
        </ol>
      </div>
    </div>
  );
};

export default DesignFirstProduct;
