import React, { useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VideoPlayer from "../../../components/common/VideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboardTasks } from "../../../actions/dashboard";
import "./styles.scss";
import AppButton from "../../../components/common/AppButton";

const VIDEO_OPTIONS = {
  1: "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2FSyncing-Consistant-Images.mp4?alt=media&token=f62d2a33-e903-4662-bd4b-deb3561c99c7",
  2: "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2FSyncing-Custom-Images.mp4?alt=media&token=56e90790-5189-4b47-a0a7-8c292761ddfb",
  3: "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2FSyncing-Templates.mp4?alt=media&token=b9893fe6-5d9d-429e-a93c-026935dd4a98",
};

const SyncYourUnsyncedOrders = () => {
  const [activePlayOption, setActivePlayOption] = useState(null);
  const dispatch = useDispatch();

  const stepsCompleted = useSelector(
    (state) => state?.dashboard?.gettingStartedStepsDone
  );

  const onPlayClick = (index) => {
    setActivePlayOption(index);

    if (!stepsCompleted.includes("15") && !stepsCompleted.includes("9_5")) {
      const params = { tasks: { orderSynced: true } };
      dispatch(updateDashboardTasks(params));
    }
  };

  return (
    <div className="my-3">
      <p>
        Now that you have connected your channel, we need to sync the products
        in the unsynced orders.
      </p>

      <div className="videoButtonsContainer">
        <AppButton className="m-1" onClick={() => onPlayClick(1)} size="slim">
          <PlayArrowIcon /> Syncing Consistent Images
        </AppButton>

        <AppButton className="m-1" onClick={() => onPlayClick(2)} size="slim">
          <PlayArrowIcon /> Syncing Custom Images
        </AppButton>

        <AppButton className="m-1" onClick={() => onPlayClick(3)} size="slim">
          <PlayArrowIcon /> Syncing Templates
        </AppButton>
      </div>

      {activePlayOption && (
        <VideoPlayer
          url={VIDEO_OPTIONS[activePlayOption]}
          open={activePlayOption}
          handleClose={() => setActivePlayOption(null)}
          onEnded={() => setActivePlayOption(null)}
          controls
        />
      )}
    </div>
  );
};

export default SyncYourUnsyncedOrders;
