import React from "react";

const SyncOrder = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        Syncing your pre-existing listings is one of our first priorities. This
        is what will help us to identify and organize your workload as it goes
        through the workflow.
      </p>
      <a
        href="https://help.riverr.app/getting-started/sync-your-listings"
        target="_blank"
        style={{
          padding: 10,
          textDecoration: "underline",
          fontSize: 14,
        }}
      >
        Learn how to sync listings
      </a>
    </div>
  );
};

export default SyncOrder;
