import { combineReducers } from "redux";

import login from "./login";
import account from "./account";
import currentUser from "./currentUser";
import layout from "./layout";
import integrations from "./integrations";
import platforms from "./platforms";
import shops from "./shops";
import suppliers from "./suppliers";
import blankProducts from "./blankProducts";
import products from "./products";
import settings from "./settings";
import items from "./items";
import batches from "./batches";
import orders from "./orders";
import purchaseOrders from "./purchaseOrders";
import addresses from "./addresses";
import shipments from "./shipments";
import inventory from "./inventory";
import bins from "./bins";
import forgetPassword from "./forgetPassword";
import common from "./common";
import pricings from "./pricings";
import dashboard from "./dashboard";
import analyticsScreen from "./analyticsScreen";
import invoices from "./invoices";
import credentials from "./credentials";

const rootReducer = combineReducers({
  login,
  account,
  currentUser,
  layout,
  integrations,
  platforms,
  shops,
  suppliers,
  blankProducts,
  products,
  settings,
  items,
  batches,
  orders,
  purchaseOrders,
  addresses,
  shipments,
  inventory,
  bins,
  forgetPassword,
  common,
  pricings,
  dashboard,
  analyticsScreen,
  invoices,
  credentials,
});

export default rootReducer;
