import React from "react";

const PrintFirstProduct = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        Need information on how to use the barcodes to print? Check out our{" "}
        <a
          href="https://help.riverr.app/en/understanding-the-production-workflow/barcodes-and-printing"
          target="_blank"
        >
          documentation
        </a>
        .
      </p>
    </div>
  );
};

export default PrintFirstProduct;
