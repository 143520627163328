import { Typography } from "@mui/material";
import React from "react";

const PrinterApp = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        Our printer applications can do it all:
        <ul>
          <li>Resize images</li>
          <li>Add the correct color profiles</li>
          <li>Rip files locally (If needed)</li>
          <li>Send the file directly to the printer or hot folder</li>
        </ul>
      </p>
      <p>
        <b>Brother Printer Application:</b>
        <br />
        This application was made specifically for Brother DTG printers.
      </p>

      <Typography variant="subtitle2" className="mb-3">
        Learn more about{" "}
        <a
          href="https://help.riverr.app/en/understanding-the-production-workflow/brother-dtg-printer-application"
          target="_blank"
          rel="noreferrer"
        >
          Brother Printer Application
        </a>
      </Typography>

      <p>
        <b>Hot Folder Application:</b>
        <br />
        This application was made for non-brother DTGs.
      </p>

      <Typography variant="subtitle2">
        Learn more about{" "}
        <a
          href="https://help.riverr.app/en/understanding-the-production-workflow/hot-folder-application"
          target="_blank"
          rel="noreferrer"
        >
          Hot Folder App
        </a>
      </Typography>
    </div>
  );
};

export default PrinterApp;
