import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { AppProvider } from "@shopify/polaris";
// import "@shopify/polaris/build/esm/styles.css";
import en from "@shopify/polaris/locales/en.json";
import { ApolloProvider } from "@apollo/client";

import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
// import * as serviceWorker from './serviceWorker';
import store from "./store";
import "./index.css";
import "./shopifyPolaris.css";
import client from "./graphQL";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AppProvider i18n={en}>
        <Provider store={store}>
          <BrowserRouter>
            <PolarisVizProvider>
              <App />
            </PolarisVizProvider>
          </BrowserRouter>
        </Provider>
      </AppProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.register();
