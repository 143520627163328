import {
  REQUEST_SUPPLIERS_SUCCESS,
  GET_BLANK_PRODUCTS_SUCCESS,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  suppliers: [],
  userCreatedSuppliers: [],
  inventoryProducts: [],
  inventoryVariants: [],
};

export default function inventory(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_SUPPLIERS_SUCCESS:
      const suppliers = action?.payload?.suppliers || INITIAL_STATE.suppliers;

      return {
        ...state,
        suppliers,
      };
    case GET_BLANK_PRODUCTS_SUCCESS:
      const inventoryProducts =
        action?.payload?.products || INITIAL_STATE.inventoryProducts;
      return {
        ...state,
        inventoryProducts,
      };
    default:
      return state;
  }
}
