import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    console.log(error, info);
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div
          style={{
            position: "absolute",
            left: "35%",
            top: "45%",
          }}
        >
          <h2
            style={{
              color: "#a94442",
            }}
          >
            {error.name === "ChunkLoadError"
              ? "This application has been updated, please refresh your browser to see the latest content."
              : "We are sorry something went wrong. An error has occurred, please refresh and try again."}
          </h2>

          <p>{error?.message}</p>
        </div>
      );
    }
    return children;
  }
}
