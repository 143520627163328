import ajax from "./ajax";

export const requestGraphData = (query) => {
  return ajax("/counts/dashboard", { query });
};

export const requestPrintingStats = () => {
  return ajax("/printing/printing_stats");
};

export const setCustomizeButtonHit = (data) => {
  return ajax("/dashboard/tasks", { method: "PUT", data });
};

export const updateDashboardTasks = (data) => {
  return ajax("/dashboard/tasks", { method: "PUT", data });
};
