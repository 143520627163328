import React from "react";

const AddProductToStore = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        Now you have the power to send your newly created products to any (Or
        every!) channel you’ve connected in seconds.
      </p>
    </div>
  );
};

export default AddProductToStore;
