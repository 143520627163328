import { createAction } from "redux-actions";

import {
  REQUEST_CURRENT_USER_SUCCESS,
  REQUEST_CURRENT_USER_FAILURE,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
} from "../constants/actionTypes";
import * as User from "../api/user";

const requestCurrentUserSuccess = createAction(REQUEST_CURRENT_USER_SUCCESS);
const requestCurrentUserFailure = createAction(REQUEST_CURRENT_USER_FAILURE);

export function requestCurrentUser() {
  return (dispatch) =>
    User.requestCurrentUser()
      .then((data) => dispatch(requestCurrentUserSuccess(data)))
      .catch((error) => dispatch(requestCurrentUserFailure(error)));
}

const createUserSuccess = createAction(CREATE_USER_SUCCESS);
const createUserFailure = createAction(CREATE_USER_FAILURE);

export function createUser(params) {
  return (dispatch) =>
    User.createUser(params)
      .then((data) => dispatch(createUserSuccess(data)))
      .catch((error) => dispatch(createUserFailure(error)));
}

const updateUserSuccess = createAction(UPDATE_USER_SUCCESS);
const updateUserFailure = createAction(UPDATE_USER_FAILURE);

export function updateUser(params) {
  return (dispatch) =>
    User.updateUser(params)
      .then((data) => dispatch(updateUserSuccess(data)))
      .catch((error) => dispatch(updateUserFailure(error)));
}

const inviteUserSuccess = createAction(INVITE_USER_SUCCESS);
const inviteUserFailure = createAction(INVITE_USER_FAILURE);

export function inviteUser(params) {
  return (dispatch) =>
    User.inviteUser(params)
      .then((data) => dispatch(inviteUserSuccess(data)))
      .catch((error) => dispatch(inviteUserFailure(error)));
}
