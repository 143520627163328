import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getPaymetMethods } from "../../../actions/credentials";
import { requestTours } from "../../../actions/settings";

const AllInitialApiCallsComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestTours());
    dispatch(getPaymetMethods());
  }, []);

  return <></>;
};

export default AllInitialApiCallsComponent;
