import React from "react";

const NewPurchaseOrder = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        We will magically create your purchase orders for you with the click of
        a few buttons.
      </p>
      <p>
        We create your purchase orders based on the blank products that you need
        to fulfill your current orders. Check out our{" "}
        <a
          href="https://help.riverr.app/en/mrp-within-riverr/creating-purchase-orders-1"
          target="_blank"
          style={{
            textDecoration: "underline",
            fontSize: 14,
          }}
        >
          documentation
        </a>{" "}
        for more detailed information about the process.
      </p>
    </div>
  );
};

export default NewPurchaseOrder;
