import ajax from "./ajax";

export function requestPlacements(query) {
  return ajax("/settings/placements", { query });
}

export function requestTheme(query) {
  return ajax("/enterprises/theme", { query });
}

export function addTheme(data) {
  return ajax("/enterprises/theme", { method: "POST", data });
}

export function createEnterprise(data) {
  return ajax("/enterprises", { method: "POST", data });
}

export function editEnterprise(data) {
  return ajax("/enterprises", { method: "PUT", data });
}

export function requestTours(query) {
  return ajax("/settings/tours", { query });
}

export function updateTours(data) {
  return ajax("/settings/tours", { method: "PUT", data });
}

export function requestPrintModes(query) {
  return ajax("/printing/print_modes", { query });
}

export function requestMaxPrintSizes() {
  return ajax("/settings/maxPrintSizes");
}

export function getPricingTemplates() {
  return ajax("/settings/pricing-templates");
}

export function addPricingTemplates(data) {
  return ajax("/settings/pricing-templates", { method: "POST", data });
}

export function editPricingTemplates(id, data) {
  return ajax(`/settings/pricing-templates/${id}`, { method: "PUT", data });
}

export function deletePricingTemplates(id) {
  return ajax(`/settings/pricing-templates/${id}`, { method: "DELETE" });
}

export function getOrderStatusColorTags() {
  return ajax("/settings/tags");
}

export function addOrderStatusColorTags(data) {
  return ajax("/settings/tags", { method: "POST", data });
}

export function getUserSettings(data) {
  return ajax("/settings/user-settings/manufacturingSettings");
}

export function saveUserSettings(data) {
  return ajax("/settings/user-settings/manufacturingSettings", {
    method: "POST",
    data,
  });
}

export function saveEnterpriseSetting(id, data) {
  return ajax(`/settings/enterprise-settings/${id}`, {
    method: "POST",
    data,
  });
}

export function getEnterpriseSetting(id) {
  return ajax(`/settings/enterprise-settings/${id}`);
}

export function saveShippingPriceUpcharge(data) {
  return ajax("/enterprises/shipping", {
    method: "POST",
    data,
  });
}

export function getShippingPriceUpcharge() {
  return ajax(`/enterprises/shipping`);
}
