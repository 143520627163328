import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const timeLineDotColor = (stepsCompleted, primaryColor, key) => {
  if (stepsCompleted.includes(key) && primaryColor) return primaryColor;
  if (stepsCompleted.includes(key)) return "#2e7d32";
  return "#fff";
};

export const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, primaryColor }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: primaryColor ? primaryColor : "#4e959e",
    },
  })
);
