import React from "react";

const DownloadingBarcodes = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        Barcodes are automatically generated by our system with all of the
        relevant information needed. You can download the barcodes from the
        batch page.
      </p>

      <div>
        <ol>
          <li>Click on one of your batches.</li>
          <li>
            On the individual batch page, click the button "Download Barcodes"
          </li>
          <li>
            After they have been downloaded, open them from your downloads
            folder and print them using your barcode printer.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default DownloadingBarcodes;
