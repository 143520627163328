import { useLocation } from "react-router";

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizedPathName = (string) => {
  return string.charAt(1).toUpperCase() + string.slice(2);
};

export const makeSearchParams = (query) => {
  return new URLSearchParams(query).toString();
};

export const ellipsify = (str, charLen = 10, spreader = "...") => {
  if (!str) {
    return "";
  }

  if (str.length > charLen) {
    return str.substring(0, charLen) + spreader;
  }

  return str;
};

export const ellipsifyAtStart = (str = "", charLen = 10, spreader = "...") => {
  if (str.length > charLen) {
    return spreader + str.substring(str.length - charLen, str.length);
  }
  return str;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
