import React from "react";

const StoreSetupIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3083 1.7917L17.8083 4.79168C18.1 4.90835 18.3334 5.25834 18.3334 5.56667V8.33335C18.3334 8.79168 17.9584 9.16668 17.5 9.16668H2.50002C2.04169 9.16668 1.66669 8.79168 1.66669 8.33335V5.56667C1.66669 5.25834 1.90002 4.90835 2.19169 4.79168L9.69169 1.7917C9.85836 1.72503 10.1417 1.72503 10.3083 1.7917Z"
      stroke="#292D32"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3334 18.3333H1.66669V15.8333C1.66669 15.375 2.04169 15 2.50002 15H17.5C17.9584 15 18.3334 15.375 18.3334 15.8333V18.3333Z"
      stroke="#292D32"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.33331 14.9998V9.1665"
      stroke="#292D32"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66669 14.9998V9.1665"
      stroke="#292D32"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 14.9998V9.1665"
      stroke="#292D32"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3333 14.9998V9.1665"
      stroke="#292D32"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.6667 14.9998V9.1665"
      stroke="#292D32"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.833313 18.3335H19.1666"
      stroke="#292D32"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 7.0835C10.6904 7.0835 11.25 6.52385 11.25 5.8335C11.25 5.14314 10.6904 4.5835 10 4.5835C9.30964 4.5835 8.75 5.14314 8.75 5.8335C8.75 6.52385 9.30964 7.0835 10 7.0835Z"
      stroke="#292D32"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default StoreSetupIcon;
