import { groupBy, keyBy } from "lodash";

import {
  REQUEST_SHIPMENTS_SUCCESS,
  REQUEST_SHIPMENTS_FAILURE,
  REQUEST_CARRIERS_SUCCESS,
  CREATE_SHIPMENT_SUCCESS,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  shipments: {},
  carriers: {},
};

export default function shipments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_SHIPMENTS_SUCCESS:
      const groupedShipments = groupBy(
        action?.payload?.data?.shipments || [],
        "orderId"
      );

      return {
        ...state,
        shipments: {
          ...state.shipments,
          ...groupedShipments,
        },
      };

    case REQUEST_SHIPMENTS_FAILURE:
      return { ...state, error: "Record not found" };

    case REQUEST_CARRIERS_SUCCESS:
      const carriers = keyBy(action?.payload?.data?.carriers || [], "id");

      return {
        ...state,
        carriers,
      };

    case CREATE_SHIPMENT_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}
