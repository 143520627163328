import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getUID, getDomainHostname, httpLink } from "../api/ajax";

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-uid": getUID(),
      "domain-hostname": getDomainHostname(),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
