// import { Card, TextContainer, Heading } from "@shopify/polaris";
import React, { useState, useEffect } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { useDispatch, useSelector } from "react-redux";

import { NEW_RETAILS_PRODUCTS_STEPS } from "../../../constants/dashboard";
import { updateCurrentActiveStep } from "../../../actions/dashboard";
import { useHistory } from "react-router-dom";
import { setCustomizeButtonHit } from "../../../api/dashboard";
import AppButton from "../../../components/common/AppButton";
import { getFirstIncompleteStep } from "../../../helpers/common";
import {
  timeLineDotColor,
  BorderLinearProgress,
} from "../../../helpers/timeline";
import { Row, Col, Card } from "reactstrap";
import { Typography, LinearProgress, IconButton } from "@mui/material";
import DashboardImage from "../../../assets/images/DashboardImage.webp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StoreSetupIcon from "../../../assets/icons/StoreSetupIcon";

function NewRetailProducts({ primaryColor, lighterPrimaryColor }) {
  const dispatch = useDispatch();
  const history = useHistory();

  // const currentActiveStep = useSelector(
  //   (state) => state?.dashboard?.currentActiveStep
  // );
  const stepsCompleted = useSelector(
    (state) => state?.dashboard?.retailProductStepsDone
  );

  let steps = Object.values(NEW_RETAILS_PRODUCTS_STEPS);
  steps = steps.sort((a, b) => a.rankOrder - b.rankOrder);

  // const firstIncompleteStep = getFirstIncompleteStep(stepsCompleted, steps);

  // const [expandedStep, setExpandedStep] = useState(
  //   currentActiveStep || firstIncompleteStep
  // );

  const onButtonClick = (key) => {
    const clickedStep = NEW_RETAILS_PRODUCTS_STEPS[key];

    if (clickedStep.mappingKey === "customizeButton") {
      setCustomizeButtonHit({ customizeButton: true })
        .then(() => {})
        .catch((err) => console.log(err));
    }

    if (clickedStep?.link) {
      return window.open(clickedStep?.link, "_blank");
    }

    dispatch(updateCurrentActiveStep(key));
    history.push(clickedStep?.redirectRoute);
  };
  const [isChecklistVisible, setChecklistVisible] = useState(true); // Checklist visibility toggle
  const toggleChecklist = () => {
    setChecklistVisible(!isChecklistVisible);
  };
  useEffect(() => {
    const drawer = document.querySelector(".MuiPaper-root");

    if (drawer) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentRect.width < 300) {
            drawer.classList.add("small-drawer");
          } else {
            drawer.classList.remove("small-drawer");
          }
        }
      });

      resizeObserver.observe(drawer);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <Card className="card-container">
      <Row className="setup-row">
        {/* Left Section */}
        {isChecklistVisible && (
          <Col md="4" className="left-section">
            <Typography variant="h5" className="heading">
              Welcome to the Family! <br />
              <span style={{ color: primaryColor }}>Let's get you set up.</span>
            </Typography>
            <img
              src={DashboardImage}
              alt="On Boarding"
              className="img-fluid"
              style={{ maxWidth: "100%", height: "auto", borderRadius: "20px" }}
            />
          </Col>
        )}
        {/* Right Section */}
        <Col md={isChecklistVisible ? "8" : "12"} className="right-section">
          <div className="header">
            <StoreSetupIcon />

            <Typography variant="h6" className="checklist-title">
              Store setup checklist
            </Typography>

            <IconButton onClick={toggleChecklist}>
              {isChecklistVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
          <div className="divider"></div>
          {isChecklistVisible && (
            <>
              {steps.map(({ key, title }, i) => (
                <div
                  key={key}
                  className="step-item"
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = lighterPrimaryColor;
                    e.currentTarget.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    onButtonClick(key);
                  }}
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "transparent")
                  }
                >
                  {/* Left part with title and progress bar */}
                  <div className="step-content">
                    <div style={{ flex: 1 }}>
                      <Typography
                        className={`step-title ${
                          stepsCompleted.includes(key) ? "bold" : ""
                        }`}
                      >
                        {title}
                      </Typography>
                    </div>
                    <div className="progress-bar-container">
                      <LinearProgress
                        variant="determinate"
                        value={stepsCompleted.includes(key) ? 100 : 10}
                        sx={{
                          marginLeft: "50px",
                          height: "10px",
                          borderRadius: "5px",
                          width: "100%",
                          alignItems: "right",
                          minWidth: "50px",
                          "@media (max-width: 500px)": {
                            width: "70%",
                          },
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: primaryColor,
                          },
                          backgroundColor: lighterPrimaryColor,
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    {stepsCompleted.includes(key) ? (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill={primaryColor}
                      >
                        <path d="M20.285 6.715l-11.285 11.285-5.285-5.285 1.415-1.415 3.87 3.87 9.87-9.87z" />
                      </svg>
                    ) : (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 8 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.6825 12.9401L6.5725 8.05006C7.15 7.47256 7.15 6.52756 6.5725 5.95006L1.6825 1.06006"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
  // return (
  //   <Card>
  //     <Card.Header title="New Retail Products"></Card.Header>
  //     <Card.Section>
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <TextContainer>
  //           {stepsCompleted.length} of {steps.length} tasks complete
  //         </TextContainer>

  //         <div style={{ flex: "1 1", marginLeft: "1rem" }}>
  //           <BorderLinearProgress
  //             variant="determinate"
  //             value={(stepsCompleted.length / steps.length) * 100}
  //             primaryColor={primaryColor}
  //           />
  //         </div>
  //       </div>
  //     </Card.Section>
  //     <Card.Section>
  //       <Timeline align="left">
  //         {steps.map(({ key, title, content, buttonText }, i) => (
  //           <TimelineItem key={key}>
  //             <TimelineOppositeContent
  //               style={{
  //                 maxWidth: "1px",
  //                 paddingLeft: "0px",
  //                 paddingRight: "0px",
  //               }}
  //             />
  //             <TimelineSeparator>
  //               <TimelineDot
  //                 sx={{
  //                   bgcolor: timeLineDotColor(
  //                     stepsCompleted,
  //                     primaryColor,
  //                     key
  //                   ),
  //                 }}
  //                 variant={stepsCompleted.includes(key) ? "filled" : "outlined"}
  //               />
  //               {i !== steps.length - 1 && <TimelineConnector />}
  //             </TimelineSeparator>
  //             <TimelineContent>
  //               <div style={{ padding: "0px 10px 20px 10px" }}>
  //                 {expandedStep !== key && (
  //                   <span role="button" onClick={() => setExpandedStep(key)}>
  //                     {title}
  //                   </span>
  //                 )}
  //                 {expandedStep === key && (
  //                   <>
  //                     <Heading>{title}</Heading>
  //                     <Typography className="my-3">{content}</Typography>
  //                     {buttonText && (
  //                       <AppButton
  //                         primary
  //                         size="slim"
  //                         onClick={() => onButtonClick(key)}
  //                       >
  //                         {buttonText}
  //                       </AppButton>
  //                     )}
  //                   </>
  //                 )}
  //               </div>
  //             </TimelineContent>
  //           </TimelineItem>
  //         ))}
  //       </Timeline>
  //     </Card.Section>
  //   </Card>
  // );
}

export default NewRetailProducts;
