import React from "react";

const EcommerceIntegrations = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        Set up your integrations with all of the ecommerce platforms that you
        would like to work with. All of the documentation for getting started is
        contained in the link associated with the platform shown on the card.
      </p>
    </div>
  );
};

export default EcommerceIntegrations;
