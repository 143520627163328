import React from "react";

const SetUpBilling = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        We want to get started fulfilling for you today! In order to do that, we
        will simply need a credit card or a bank account to get started.
      </p>
    </div>
  );
};

export default SetUpBilling;
