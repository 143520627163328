import React from "react";
import { useSelector } from "react-redux";
import { currentDomain } from "../../utils";

const ConnectChannelStep = () => {
  const theme = useSelector((state) => state?.settings?.themeData || {});

  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        <b>Step 1: </b>Click on the “Add Shop” Button
      </p>
      <p>
        <b>Step 2: </b>Choose the platform you would like to add
      </p>
      <p>
        <b>Step 3: </b>Login to the com channel account and allow access to{" "}
        {theme?.enterprise?.name || currentDomain}.
      </p>
      <p>
        You should now see the channel available in your{" "}
        {theme?.enterprise?.name || currentDomain} account.
      </p>
      <p>Done! It’s that easy.</p>
    </div>
  );
};

export default ConnectChannelStep;
