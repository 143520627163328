import {
  REQUEST_ANALYTICS_DETAILS_SUCCESS,
  REQUEST_ANALYTICS_DETAILS_FAILURE,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  analyticsDetails: {},
};

export default function analyticsScreen(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_ANALYTICS_DETAILS_SUCCESS:
      const analyticsDetails = action?.payload?.data || {};

      return {
        ...state,
        analyticsDetails: analyticsDetails,
      };

    case REQUEST_ANALYTICS_DETAILS_FAILURE:
      return { ...state, error: "Record not found" };

    default:
      return state;
  }
}
