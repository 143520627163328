import React from "react";

const QualityControl = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        What should you do after an order is printed? Ensure that the printed
        item is correct and up to quality standards before sending it to the
        shipping station.
      </p>

      <a
        href="https://help.riverr.app/en/understanding-the-production-workflow/quality-control"
        target="_blank"
      >
        Intro to Quality Control
      </a>
    </div>
  );
};

export default QualityControl;
