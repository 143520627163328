import { uniqBy } from "lodash";
import {
  REQUEST_ITEMS_SUCCESS,
  REQUEST_ITEMS_FAILURE,
  REQUEST_FILTERED_ITEMS_SUCCESS,
  CREATE_BATCH_SUCCESS,
  SHOW_LOADING_FALSE,
  SHOW_LOADING_TRUE,
  BATCH_BY_ORDER_SUCCESS,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  items: [],
  filters: {},
  inventory: [],
  error: null,
  lastItemId: "",
  filterLoading: false,
  showCreateBatch: false,
  batchByOrder: false,
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_ITEMS_SUCCESS:
      const {
        items = [],
        filters = {},
        inventory = [],
      } = action?.payload?.data || [];

      return {
        ...state,
        items: uniqBy([...state.items, ...items], (x) => x.id),
        filters,
        inventory,
      };

    case REQUEST_ITEMS_FAILURE:
      return { ...state, error: "Record not found" };

    case REQUEST_FILTERED_ITEMS_SUCCESS:
      const newFilteredItems = (action?.payload?.items || []).filter((f) => f);
      const newFilters = {
        ...state.filters,
        ...(action?.payload?.filters || {}),
      };

      return {
        ...state,
        items: uniqBy(newFilteredItems, (x) => x.id),
        filters: newFilters,
        error: null,
      };

    case CREATE_BATCH_SUCCESS:
      const remainingItems = state.items.filter(
        (f) => !(action?.payload?.orderItemIds || [])?.includes(`${f.id}`)
      );
      return {
        ...state,
        items: remainingItems,
      };

    case SHOW_LOADING_FALSE:
      return {
        ...state,
        filterLoading: false,
      };
    case SHOW_LOADING_TRUE:
      return {
        ...state,
        filterLoading: true,
      };

    case BATCH_BY_ORDER_SUCCESS:
      return {
        ...state,
        batchByOrder: action.payload,
      };

    default:
      return state;
  }
}
