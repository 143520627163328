export const ROLE_OPTIONS = {
  admin: { value: "admin", name: "Admin" },
  member: { value: "member", name: "Member" },
};

export const USER_TO_BYPASS_ALERTS = [
  "fashionbeau@gmail.com",
  "ryan2@thehomet.com",
  "mohamedassalhi98@gmail.com",
];

export const LOCALSTORAGE_KEYS = {
  authUser: "authUser",
  mockUser: "mockUser",
};
