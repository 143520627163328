import {
    REQUEST_CURRENT_USER_SUCCESS,
    REQUEST_CURRENT_USER_FAILURE,
  } from "../../constants/actionTypes";
  
  const INITIAL_STATE = {
    user: {}
  };
  
  export default function currentUser(state = INITIAL_STATE, action) {
    switch (action.type) {
      case REQUEST_CURRENT_USER_SUCCESS:
        return { ...action.payload.data };
      case REQUEST_CURRENT_USER_FAILURE:
        return { ...state, error: "Record not found" };
      default:
        return state;
    }
  }
  