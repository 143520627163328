import {
  REQUEST_PLATFORMS_SUCCESS,
  REQUEST_PLATFORMS_FAILURE,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  platforms: [],
};

export default function platforms(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_PLATFORMS_SUCCESS:
      return {
        platforms: action?.payload?.data?.platforms || [],
      };

    case REQUEST_PLATFORMS_FAILURE:
      return { ...state, error: "Record not found" };

    default:
      return state;
  }
}
