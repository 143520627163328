import { keyBy, omit } from "lodash";

import {
  REQUEST_ADDRESSES_SUCCESS,
  CREATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_SUCCESS,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  addresses: {},
};

export default function addresses(state = INITIAL_STATE, action) {
  let { addresses = [], address = {} } = action?.payload || {};
  addresses = keyBy(addresses, "id");

  switch (action.type) {
    case REQUEST_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses,
      };

    case CREATE_ADDRESS_SUCCESS:
      const updatedAddresses = { ...state.addresses, [address?.id]: address };

      return {
        ...state,
        addresses: updatedAddresses,
      };

    case UPDATE_ADDRESS_SUCCESS:
      const afterUpdate = {
        ...state.addresses,
        [action?.payload?.id]: action?.payload?.data?.address,
      };

      return {
        ...state,
        addresses: afterUpdate,
      };

    case DELETE_ADDRESS_SUCCESS:
      const afterDelete = omit(state?.addresses, action?.payload?.id);

      return {
        ...state,
        addresses: afterDelete,
      };

    default:
      return state;
  }
}
