import ajax from "./ajax";

export function requestCurrentUser() {
  return ajax("/current_user?enterprise=true");
}

export function createUser(data) {
  return ajax("/current_user", { method: "POST", data });
}

export function updateUser(data) {
  return ajax("/current_user", { method: "PUT", data });
}

export function deleteUser(userId) {
  return ajax(`/users/${userId}`, { method: "DELETE" });
}

export function inviteUser(data) {
  return ajax(`/users/invitation`, { method: "POST", data });
}

export function updateEnterpriseUser(id, data) {
  return ajax(`/users/enterprise-user/${id}`, { method: "PUT", data });
}
