import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER,
    REGISTER_USER_FAILED,
  } from "../constants/actionTypes";
  
  export const loginUser = (user, history) => {
    return {
      type: LOGIN_USER,
      payload: { user, history },
    };
  };
  
  export const loginSuccess = (user) => {
    return {
      type: LOGIN_SUCCESS,
      payload: user,
    };
  };
  
  export const logoutUser = (history) => {
    return {
      type: LOGOUT_USER,
      payload: { history },
    };
  };
  
  export const logoutUserSuccess = () => {
    return {
      type: LOGOUT_USER_SUCCESS,
      payload: {},
    };
  };
  
  export const apiError = (error) => {
    return {
      type: API_ERROR,
      payload: error,
    };
  };
  
  export const registerUser = (user, history) => {
    return {
      type: REGISTER_USER,
      payload: { user, history },
    };
  };
  
  export const registerUserSuccessful = (user) => {
    return {
      type: REGISTER_USER_SUCCESSFUL,
      payload: user,
    };
  };
  
  export const registerUserFailed = (user) => {
    return {
      type: REGISTER_USER_FAILED,
      payload: user,
    };
  };
  